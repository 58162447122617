export const Menu = [
    {
        label: 'Home',
        loc: "Video",
        cName: 'nav-links'
    },
    {
        label: 'About Me',
        loc: "About",
        cName: 'nav-links'
    },
    {
        label: 'Projects/Experience',
        loc: "Projects",
        cName: 'nav-links'
    },
    {
        label: 'Contact',
        loc: "Contact",
        cName: 'nav-links'
    },
]